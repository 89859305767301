.picker-menu {
	position: relative;
	font-size: 0.9em;
	user-select: none;
	flex:1;
	margin: 25px 0;
	border:1px solid transparent;
	overflow: hidden;

	label {
		color: black;
	}

	.picker-row {
		display: flex;
		flex-direction: row;
	}

	.picker-select-box {
		background-color: #333333;
		color: white;
		cursor: pointer;
		padding: 0 20px;
		min-height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;

		.arrow {
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;

			border-bottom: 5px solid #8f8f8f;
			transition: 0.2s;
			transform: rotate(180deg);
		}

		.arrow.down {
			transform: rotate(180deg);
		}

		.arrow.up {
			transform: rotate(0deg);
		}
	}

	.select-box.selected {

	}

	.picker-options-container  {
		position: relative;
		z-index: 100;
		background-color: #2f2f2f;
		overflow: scroll;
		overflow-x: hidden;
		color: white;
		transition: 0.2s;

		.item {
			min-height: 30px;
			width: 100%;
			transition: 0.2s;
			display: flex;
			align-items: center;
			padding: 5px 20px;
			user-select: none;
			cursor: pointer;
		}
		.item:hover {
			// background-color: #4A90E2;
		}
	}
}


