.container {
	display: inline-block;
	background-color: black;
	position: relative;
}

.playOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,0.1);
	pointer-events: all;
	opacity: 1;
	/*transition: 0.25s;*/
	z-index: 100;
}

.playOverlay.hidden {
	opacity: 0;
	pointer-events: none;
}

.overlayPlayButton {
	height: 80px;
	width: 80px;
	background-color: rgba(255,255,255,0.9);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	transition: 0.2s;
}

.overlayPlayButton:hover {
	cursor: pointer;
	background-color: rgba(255,255,255,1);
	transform: scale(0.95);
}

.loadContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loadingContainer {
	height: 100%;
	width: 100%;
	border-radius: 2.5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loadingBar {
	-webkit-animation: barLoad 3s infinite;
	width: 25px;
	height: 10px;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	border-radius: 2.5px;
	overflow: hidden;
}

.lds-ring.small {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: -8px;
}

.lds-ring.small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 8px solid #4A90E2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4A90E2 transparent transparent transparent;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




@keyframes barLoad {
  0% {
  	width: 25%;
  	margin-left: -25%; 
  	background-position: 0% 50%;
  }

  50% {
  	width: 50%;
  	background-position: 200% 100%;
  }

  100% {
  	width: 25%;
    margin-left: 100%;
    background-position: 0% 50%;
  }
}

.superCarousel {
	width: 100%;
	overflow: hidden;	
}

.carousel {
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	flex-direction: row;
	margin-top: 5px;
	z-index: 1000;

	margin-bottom:-20px;

	-webkit-overflow-scrolling: touch;
}

.carousel::-webkit-scrollbar-track {
  background-color: transparent;
}

.carousel::-webkit-scrollbar {
  background-color: transparent;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.wedge {
	display: block;
	padding-left: 10px;
	background-color: transparent;
}

.carouselItem {
	width: 105px;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;

	margin: 5px;
	padding: 15px;

	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

	color: black;
	text-decoration: none;
}


.carouselImage {
	width: auto;
	height: 100px;
	border-radius: 6px;
}

.carouselPrice {
	font-size: 14px;
	opacity: 0.8;
}

.tooltipContainer {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 50;
	pointer-events: none;

	background-color: transparent;
	transition: 0.5s;
}

.tooltipBlur {
	pointer-events: all;
}

.add-video-enter {}
.add-video-enter-active {}
.add-video-exit {
  opacity: 1;
}
.add-video-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: 250ms;
}

.tooltip-wrapper {
	height: 100%;
	width: 100%;
}

.freckle-overlay {
	position: absolute;
	background-color: transparent;
	height: 80px;
	width: 80px;
	margin-left:-10px;
	margin-top:-10px;
	cursor: pointer;
}

@keyframes enterTooltipAnim {
  0% {
  	opacity: 0;
    transform: scale(0.5);
  }
  100% {
  	opacity: 1;
    transform: scale(1);
  }
}

.videoContainer {
	display:flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	height:100%;
	position: relative;
}

video {
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	object-fit: contain;
	pointer-events: none;
	outline: none; 
	background-color: black;
	transition: 0.25s;
	// border:2px solid yellow;
}

@media (min-aspect-ratio: 1/1) {
	video {
		min-height: 1px;
	}

	.carousel {
		justify-content: center;
		align-items: center;
	}
}

.freckleLayer {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 20;
}

.controlsSuperContainer {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.controlsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(100%);
	z-index: 40;
	position: absolute;
	bottom: 0px;
	background: linear-gradient(0, rgba(0,0,0,0.75), rgba(0,0,0,0.2), transparent);
}

.controlsContainer.show {
	animation: showControlsAnim 150ms normal forwards;
}
@keyframes showControlsAnim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1.0;
    transform: translateY(0);
  }
}

.controlsContainer.hide {
	animation: hideControlsAnim 150ms normal forwards;
}
@keyframes hideControlsAnim {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.volume-container {
	height: 40px;
	width: 40px;
	margin: 0 5px;
	border-radius: 20px;
	overflow: hidden;
	transition: 0.2s;
	background-color: transparent;
}

.volume-button:hover {
	background-color: rgba(255,255,255,0.1);
}

.volume-button {
	height: 40px;
	width: 40px;
	outline: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 15px 15px;
}

.volume-button.muted {
	background-position: calc(50% + 1px) 50%;
}

.progressContainer {
	background-color: transparent;
	height: 20px;
	flex: 1;
	overflow: hidden;
	cursor: pointer;
	position: relative;
}

.progressTrack {
	height: 5px;
	border-radius: 2.5px;
	width: 100%;
    background-color: rgba(255,255,255,0.5);
	opacity: 0.75;
	pointer-events: none;
	position: absolute;
	top:7.5px;
	left:0;
	overflow: hidden;
}

.progressBar {
	height: 5px;
	border-radius: 2.5px;
	width: 100%;
    background-color: white;
	/*opacity: 0.75;*/
	pointer-events: none;
	position: absolute;
	top:0;
	left:0px;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


.controlButton {
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 40px;
	width: 40px;
	margin: 5px;
	border-radius: 25px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position: center;

	cursor: pointer;

	transition: 0.2s;

	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.controlButton:hover {
	background-color: rgba(255,255,255,0.1)
}

.pause {
	
}

.play {
	
}


.freckleItem {
  -webkit-animation-timing-function: linear;
  animation-play-state: paused;
  display: inline-block;
  position: absolute;
  user-select: none;
  width: 70px;
  height: 70px;
  margin-left: -25px;
  margin-top: -25px;
  display:flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  pointer-events: all;
}

.freckleItem:hover {
  cursor: pointer;
}

.freckleViz {
  transform: scale(1);
  transition: 0.25s;
  display: inline-block;
  background-color: transparent;
  border-radius: 50%;
  overflow: visible;
  opacity: 1;
  -webkit-animation: none;
}


.freckleInnerItem {
  -webkit-animation: pulse 2s linear infinite;
  pointer-events: auto;
  height: 30px;
  width: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transition: 0.25s;
  background-color: transparent;
}

@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(0deg);
  }
  
  25% {
    transform: scale(1.1) rotate(90deg);
  }

  50% {
    transform: scale(0.95) rotate(180deg);
  }

  75% {
    transform: scale(1.1) rotate(270deg);
  }

  100% {
    transform: scale(0.95) rotate(360deg);
  }
}

