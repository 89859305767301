.swiper-container-thumbs {
	padding: 10px 10px !important;
	padding-top: 10px !important;
}

.swiper-container {
	padding: 0;
	margin: 0;
	outline: none;
	
}

.swiper-wrapper {
	height: auto !important;
}

.swiper-slide {
	padding: 0;
	margin: 0;
	height: auto !important;
}

.exit-button {
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	top: 5px;
	left: 5px;
	z-index: 100;
	cursor: pointer;
	height: 35px;
	width: 35px;
	padding: 10px;
	border: none;
	outline: none;
	background-color: transparent;

	img {
		height: 100%;
		width: 100%;
		pointer-events: none;
	}
}

.product-page-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	transition: 0.2s;
	pointer-events: none;

	.tooltip {
		pointer-events: all;
		height: 80vh;
		width: 95vw;
		max-width: 450px;
		max-height: 700px;
		background-color: white;
		border-radius: 10px;

		display: flex;
		flex-direction: column;
		align-items: center;

		overflow: hidden;

		pointer-events: all;

		position: relative;
		z-index:100;

		font-family: Helvetica;

		box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.15);

		animation: enterTooltipAnim 350ms normal forwards;

		.scroll {
			overflow: scroll;
			width: 100%;
			height: 100%;
			padding-bottom: 60px;

			h3.product-title {
				font-weight: bold;
				font-size: 1.5em;
				color: black;
				padding: 0;
				margin: 0;
			}

			h4.product-price {
				font-weight: medium;
				font-size: 1.0em;
				color: #8f8f8f;
				padding: 0;
				margin: 0;
			}

			.description {
				margin-top:15px;
				font-weight: normal;
				font-size: 1em;	
				color: black;
			}

			.details {
				padding-top: 0;
				padding: 2em;
			}
		}

		.product-image {
			// height: 100%;
			width: 100%;
			overflow: hidden;
			object-fit: contain;
			cursor: pointer;
			user-select: none;
		}

		.product-thumb {
			// height: 100%;
			width: calc(100% - 10px);
			overflow: hidden;
			object-fit: contain;
			cursor: pointer;
			padding: 0 5px;
			user-select: none;
		}

		.buy-now-button {
			text-decoration: none;
			color: white;
			margin: 0;
			margin-bottom:0px;
			outline: 0;
			border: 0;
			height: 45px;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.2s, color 0.2s, border 0.2s;
			font-size: 12px;
			font-weight: bold;

			letter-spacing: 0.5px;

			display: flex;
			justify-content: center;
			align-items: center;

			position: fixed;
			bottom: 10px;
			width: calc(100%  - 20px);

			z-index: 100;

			text-transform: uppercase;

			background-color: #3781D8;
			box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
		}

		.buy-now-button:hover {
			// background-position: 100% 50%;
			background-color: #E73C7E;
			color: white;
		}
	}
}