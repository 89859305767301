.microsite {
	min-height: 100vh;
	.player-container {
		width: 100%;
		min-height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.header {
		background-color: transparent;
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.shim {
			height: 100%;
			width: 60px;
			margin: 0 10px;
		}

		.cart-button {
			height: 100%;
			width: 50px;	
			margin: 0 10px;
			outline: none;
			border: none;
			background-color: transparent;
			cursor: pointer;
			position: relative;

			.cart-img {
				max-width: 100000000px;
			}

			.cart-count {
				position: absolute;
				right: 3px;
				bottom: 3px;
				background-color: red;
				border-radius: 1000px;
				min-height: 20px;
				min-width: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
				font-size: 12px;
				font-weight: bold;
			}
		}

		img {
			height: 90%;
			max-width: min(400px, 70%);
		}
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;

		a {
			text-decoration: none;
			outline: 0;
			border: 0;
			color: black;
			height: 45px;
			padding: 0 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: color 0.25s, background-color 0.25s, border 0.25s;
			font-size: 0.9em;
			font-weight: bold;
		}
	}
}


.show-cart-enter {
	transform: translateX(400px);
}
.show-cart-enter-active {
	transform: translateX(0);
	transition: 250ms;
}
.show-cart-exit {
  opacity: 1;
  transform: translateX(0);
}
.show-cart-exit-active {
  transform: translateX(400px);
  transition: 250ms;
}
